import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import 'reflect-metadata';
import { FrontendPassportApiClient, PassportClientLoginException } from 'lib-passport-client';
import { COMPANY_ROLE_CD_VARIABLES } from 'lib-tw-common';
import companyRoleAccessiblePages from '@/dictionaries/companyRoles.json'
import { getArrayOfCompanyRoleLabel, getArrayOfUserRoleLabel } from '../utils/transformUserInfomation';

Vue.use(VueRouter);


const _flagNameMapper = {
  VIEWER: 'viewerFlg',
  PIC: 'picFlg',
  APPROVER: 'approverFlg',
  COMPANY_ADMIN: 'companyAdminFlg',
  TW_ADMIN: 'twAdminFlg'
};
/**
 * Navigation guard to restrict access based on user roles.
 *
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @param {Array<'VIEWER'|'PIC'|'APPROVER'|'COMPANY_ADMIN'|'TW_ADMIN'>} roles - チェック対象のユーザーロール
 * @param {Boolean} isAllowed - trueの場合はユーザーロールに遷移を許可・falseの場合はユーザーロールに遷移を禁止
 * @returns {void}
 */
// 2024年6月月次にて使用予定だったが、いったん不使用となったため使用箇所なし
// eslint-disable-next-line no-unused-vars
export const navigationGuardForUserRoles = (to, from, next, roles, isAllowed) => {
  // ユーザーロールが取得できていない場合は404
  if(!store.state.userInformationV2 || Object.keys(store.state.userInformationV2).length === 0) {
    next({ name: 'Page404' });
    return;
  }
  
  // ユーザーロールの中にアクセス可能なロールがあれば遷移可能
  if(isAllowed) {
    for(const role of roles) {
      if(store.state.userInformationV2?.[_flagNameMapper[role]]) {
        next();
        return;
      }
    }
    next({ name: 'Page404' });
  // ユーザーロール全てが遷移不可能な場合に404
  } else {
    // eslint-disable-next-line no-unused-vars
    const userRoles = Object.entries(_flagNameMapper).filter(([key, val]) => {
      return store.state.userInformationV2[val] === 1;
    // eslint-disable-next-line no-unused-vars
    }).map(([key, val]) => key);
    
    for(const role of userRoles) {
      if(!roles.includes(role)) {
        next();
        return;
      }
    }
    next({ name: 'Page404' });
  }
};

/**
 * Navigation guard to restrict access based on company roles.
 *
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @param {Array<'EXPORTER'|'IMPORTER'|'INTERMEDIATE'|'EXPORT_FREIGHT_FORWARDER'|'MARINE_INSURANCE' | 'VESSEL_AIRCRAFT' | 'NEGOTIATING_BANK' | 'CHAMBER_OF_COMMERCE_AND_INDUSTRY'>} roles - チェック対象のユーザーロール
 * @param {Boolean} isAllowed - trueの場合は会社ロールに遷移を許可・falseの場合は会社ロールに遷移を禁止
 * @returns {void}
 */
export const navigationGuardForCompanyRoles = (to, from, next, roles, isAllowed) => {
  const userCompanyRoleCodes = store.state?.companyRoles;
  // 会社ロールが取得できていない場合は404
  if(!userCompanyRoleCodes?.length) {
    next({ name: 'Page404' });
    return;
  }
  
  // eslint-disable-next-line no-unused-vars
  const userCompanyRoleKeys = Object.entries(COMPANY_ROLE_CD_VARIABLES).filter(([key, code]) => userCompanyRoleCodes.includes(code)).map(([key, code]) => key);
  // 会社ロールの中にアクセス可能なロールがあれば遷移可能
  if(isAllowed) {
    const isAccessible = userCompanyRoleKeys.some(role => roles.includes(role));
    if(isAccessible) next();
    else next({ name: 'Page404' });
    return;
  // 会社ロール全てが遷移不可能な場合に404
  } else {
    const isInaccessible = userCompanyRoleKeys.every(role => roles.includes(role));
    if(isInaccessible) next({ name: 'Page404' });
    else next();
    return;
  }
};

const routes = [
  // ダッシュボード
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "DashBoard" */ '@/views/DashBoard.vue')
  },
  // 契約一覧
  {
    path: '/entity/contract/',
    name: 'ListSalesContract',
    component: () => import(/* webpackChunkName: "ListSalesContract" */ '@/views/ListSalesContract.vue')
  },
  // LC一覧
  {
    path: '/entity/lc/',
    name: 'ListLC',
    component: () => import(/* webpackChunkName: "ListLC" */ '@/views/ListLC.vue')
  },
  // 輸出通関一覧
  {
    path: '/entity/ex-customs-clearance/',
    name: 'ListExCustomsClearance',
    component: () => import(/* webpackChunkName: "ListExCustomsClearance" */ '@/views/ListExCustomsClearance.vue')
  },
  // 輸送一覧
  {
    path: '/entity/transport/',
    name: 'ListTransport',
    component: () => import(/* webpackChunkName: "ListTransport" */ '@/views/ListTransport.vue')
  },
  // 海上保険一覧
  {
    path: '/entity/marine-insurance/',
    name: 'ListMarineInsurance',
    component: () => import(/* webpackChunkName: "ListMarineInsurance" */ '@/views/ListMarineInsurance.vue')
  },
  // 決済一覧
  {
    path: '/entity/final-documents/',
    name: 'ListFinalDocs',
    component: () => import(/* webpackChunkName: "ListFinalDocs" */ '@/views/ListFinalDocs.vue')
  },
  // 輸入通関一覧
  {
    path: '/entity/im-customs-clearance/',
    name: 'ListImCustoms',
    component: () => import(/* webpackChunkName: "ListImCustomsClearance" */ '@/views/ListImCustomsClearance.vue')
  },
  // 輸出許可書プール一覧
  {
    path: '/pool/ex-customs-permit/',
    name: 'ListExCustomsPermit',
    component: () => import(/* webpackChunkName: "ListExportCustomsPermitList" */ '@/views/ListExportCustomsPermitList.vue')
  },
  // 輸入許可書プール一覧
  {
    path: '/pool/im-customs-permit/',
    name: 'ListImCustomsPermit',
    component: () => import(/* webpackChunkName: "ListImportCustomsPermitList" */ '@/views/ListImportCustomsPermitList.vue')
  },
  // I/P, D/Nプール一覧
  {
    path: '/pool/ip-dn/',
    name: 'ListIpDnPool',
    component: () => import(/* webpackChunkName: "ListIpDnPoolList" */ '@/views/ListIpDnPoolList.vue')
  },
  // L/Cプール一覧
  {
    path: '/pool/lc/',
    name: 'ListLcPool',
    component: () => import(/* webpackChunkName: "ListLcPoolList" */ '@/views/ListLcPoolList.vue')
  },
  // お知らせ一覧
  {
    path: '/news/',
    name: 'NewsList',
    component: () => import(/* webpackChunkName: "NewsList" */ '@/views/NewsList.vue'),
  },
  // お知らせ詳細
  {
    path: '/news/:informationId/',
    name: 'NewsDetail',
    props: true,
    component: () => import(/* webpackChunkName: "NewsDetail" */ '@/views/NewsDetail.vue'),
  },
  // メール一覧
  {
    path: '/mail/',
    name: 'MailHistory',
    component: () => import(/* webpackChunkName: "MailList" */ '@/views/MailList.vue'),
  },
  // メール詳細（ドロワー）
  {
    path: '/mail/:noticeHistoryId/',
    component: () => import(/* webpackChunkName: "MailList" */ '@/views/MailList.vue'),
    children: [
      {
        path: '',
        name: 'MailDetail',
        props: true,
        component: () => import(/* webpackChunkName: "TwMailDetailInner" */ '@/components/templates/TwMailDetailInner.vue'),
      }
    ]
  },
  // メール詳細（フル画面）
  {
    path: '/mail/full/:noticeHistoryId/',
    name: 'MailDetailFull',
    props: true,
    component: () => import(/* webpackChunkName: "MailDetail" */ '@/views/MailDetail.vue'),
  },
  // プロセス詳細からのメール詳細（フル画面）
  {
    path: '/mail/full/:noticeHistoryId/:processId/:entityId/:processSeq/',
    name: 'MailDetailFullProcess',
    props: route => ({ processTrx: route.query.processTrx, ...route.params }),
    component: () => import(/* webpackChunkName: "MailDetail" */ '@/views/MailDetail.vue'),
  },
  // プロフィール
  {
    path: '/profile/',
    name: 'UserProfile',
    props: true,
    component: () => import(/* webpackChunkName: "UserProfile" */ '@/views/UserProfile.vue'),
  },
  // アーカイブ一覧
  {
    path: '/archive/',
    name: 'Archive',
    component: () => import(/* webpackChunkName: "ListArchive" */ '@/views/ListArchive.vue'),
  },
  // 承認一覧
  {
    path: '/approval/',
    name: 'Approval',
    // 担当者は遷移はできるが、bffのforbiddenエラーとするため、ナビゲーションガードは行わない
    // beforeEnter(to, from, next) {
    //   navigationGuardForUserRoles(to, from, next, ['PIC'], false);
    // },
    component: () => import(/* webpackChunkName: "ListApproval" */ '@/views/ListApproval.vue'),
  },
    // To確認中一覧
    {
      path: '/confirmation-to/',
      name: 'ConfirmationTo',
      component: () => import(/* webpackChunkName: "ListConfirmationToAndNotAccepted" */ '@/views/ListConfirmationToAndNotAccepted.vue'),
    },
    // 差戻一覧
    {
      path: '/not-accepted/',
      name: 'NotAccepted',
      component: () => import(/* webpackChunkName: "ListProcess" */ '@/views/ListConfirmationToAndNotAccepted.vue'),
    },
  // 添付一覧
  {
    path: '/attach/',
    name: 'ListAttachFile',
    component: () => import(/* webpackChunkName: "ListAttachFile" */ '@/views/ListAttachFile.vue'),
  },
  // 取引一覧
  {
    path: '/trading/list/',
    name: 'ListTrading',
    props: true,
    component: () => import(/* webpackChunkName: "TradingList" */ '@/views/TradingList.vue'),
    beforeEnter(to, from, next) {
      // eslint-disable-next-line no-unused-vars
      const rolesForListTrading = Object.entries(companyRoleAccessiblePages.find(item => item.page === 'ListTrading')).filter(([key,val]) => val === true).map(([key, val]) => key);
      navigationGuardForCompanyRoles(to, from, next, rolesForListTrading, true);
    }
  },
  // {
  //   path: '/trading/flow/',
  //   name: 'ListTradingFlow',
  //   props: true,
  //   component: () => import(/* webpackChunkName: "TradingListFlow" */ '@/views/TradingListFlow.vue'),
  // },
  {
    path: '/calendar/',
    name: 'ListTradingCalendar',
    props: true,
    component: () => import(/* webpackChunkName: "TradingListCalendar" */ '@/views/TradingListCalendar.vue'),
  },

  // 取引新規登録
  {
    path: '/trading/new/',
    name: 'NewTrading',
    props: true,
    component: () => import(/* webpackChunkName: "NewTrading" */ '@/views/NewTrading.vue'),
    beforeEnter(to, from, next) {
      // eslint-disable-next-line no-unused-vars
      const rolesForNewTrading = Object.entries(companyRoleAccessiblePages.find(item => item.page === 'NewTrading')).filter(([key,val]) => val === true).map(([key, val]) => key);
      navigationGuardForCompanyRoles(to, from, next, rolesForNewTrading, true);
    }
  },
  // 取引新規登録（下書き）
  {
    path: '/trading/new/:tradingId/',
    name: 'SavedTrading',
    props: true,
    component: () => import(/* webpackChunkName: "NewTrading" */ '@/views/NewTrading.vue'),
    beforeEnter(to, from, next) {
      // eslint-disable-next-line no-unused-vars
      const rolesForNewTrading = Object.entries(companyRoleAccessiblePages.find(item => item.page === 'NewTrading')).filter(([key,val]) => val === true).map(([key, val]) => key);
      navigationGuardForCompanyRoles(to, from, next, rolesForNewTrading, true);
    }
  },
  // 取引管理
  {
    path: '/trading/:tradingId/',
    name: 'ManageTrading',
    props: true,
    component: () => import(/* webpackChunkName: "ManageTrading" */ '@/views/ManageTrading.vue'),
    beforeEnter(to, from, next) {
      // eslint-disable-next-line no-unused-vars
      const rolesForManageTrading = Object.entries(companyRoleAccessiblePages.find(item => item.page === 'ManageTrading')).filter(([key,val]) => val === true).map(([key, val]) => key);
      navigationGuardForCompanyRoles(to, from, next, rolesForManageTrading, true);
    }
  },
  // プロセス詳細 COPO
  {
    path: '/process/copo/:entityId/:processSeq/',
    name: 'ProcessCopo',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessCOPO.vue'),
  },
  // プロセス変更登録 COPO
  {
    path: '/process/copo/edit/:entityId/:processSeq/',
    name: 'EditProcessCopo',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessCOPO.vue'),
  },
  // プロセス詳細 COCOS
  {
    path: '/process/cocos/:entityId/:processSeq/',
    name: 'ProcessCocos',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessCOCOS.vue'),
  },
  // プロセス変更登録 COCOS
  {
    path: '/process/cocos/edit/:entityId/:processSeq/',
    name: 'EditProcessCocos',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessCOCOS.vue'),
  },
  // プロセス詳細 LCACCEPT
  {
    path: '/process/lcaccept/:entityId/:processSeq/',
    name: 'ProcessLcaccept',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessLCACCEPT.vue'),
  },
  // プロセス変更登録 LCACCEPT
  {
    path: '/process/lcaccept/edit/:entityId/:processSeq/',
    name: 'EditProcessLcaccept',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessLCACCEPT.vue'),
  },
  // プロセス詳細 TPARB
  {
    path: '/process/tparb/:entityId/:processSeq/',
    name: 'ProcessTparb',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPARB.vue'),
  },
  // プロセス変更登録 TPARB
  {
    path: '/process/tparb/edit/:entityId/:processSeq/',
    name: 'EditProcessTparb',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPARB.vue'),
  },
  // プロセス詳細 ECREQ
  {
    path: '/process/:_processId(ecreq[0-9]*)/:entityId/:processSeq/',
    name: 'ProcessEcreq',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessECREQ.vue'),
  },
  // プロセス変更登録 ECREQ
  {
    path: '/process/:_processId(ecreq[0-9]*)/edit/:entityId/:processSeq/',
    name: 'EditProcessEcreq',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessECREQ.vue'),
  },
  // プロセス詳細 ECPERMIT
  {
    path: '/process/ecpermit/:entityId/:processSeq/',
    name: 'ProcessEcpermit',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessECPERMIT.vue'),
  },
  // プロセス変更登録 ECPERMIT
  {
    path: '/process/ecpermit/edit/:entityId/:processSeq/',
    name: 'EditProcessEcpermit',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessECPERMIT.vue'),
  },
  // プロセス詳細 TPRB
  {
    path: '/process/tprb/:entityId/:processSeq/',
    name: 'ProcessTprb',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPRB.vue'),
  },
  // プロセス変更登録 TPRB
  {
    path: '/process/tprb/edit/:entityId/:processSeq/',
    name: 'EditProcessTprb',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPRB.vue'),
  },
  // プロセス詳細 TPRBL
  {
    path: '/process/tprbl/:entityId/:processSeq/',
    name: 'ProcessTprbl',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPRBL.vue'),
  },
  // プロセス変更登録 TPRBL
  {
    path: '/process/tprbl/edit/:entityId/:processSeq/',
    name: 'EditProcessTprbl',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPRBL.vue'),
  },
  // プロセス詳細 TPBL
  {
    path: '/process/tpbl/:entityId/:processSeq/',
    name: 'ProcessTpbl',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPBL.vue'),
  },
  // プロセス変更登録 TPBL
  {
    path: '/process/tpbl/edit/:entityId/:processSeq/',
    name: 'EditProcessTpbl',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPBL.vue'),
  },
  // プロセス詳細 TPBLI
  {
    path: '/process/:_processId(tpbli[0-9]*)/:entityId/:processSeq/',
    name: 'ProcessTpbli',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPBLI.vue'),
  },
  // プロセス変更登録 TPBLI
  {
    path: '/process/:_processId(tpbli[0-9]*)/edit/:entityId/:processSeq/',
    name: 'EditProcessTpbli',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPBLI.vue'),
  },
  // プロセス詳細 MIRIP
  {
    path: '/process/:_processId(mirip[0-9]*)/:entityId/:processSeq/',
    name: 'ProcessMirip',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessMIRIP.vue'),
  },
  // プロセス変更登録 MIRIP
  {
    path: '/process/:_processId(mirip[0-9]*)/edit/:entityId/:processSeq/',
    name: 'EditProcessMirip',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessMIRIP.vue'),
  },
  // プロセス詳細 MIIP
  {
    path: '/process/miip/:entityId/:processSeq/',
    name: 'ProcessMiip',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessMIIP.vue'),
  },
  // プロセス変更登録 MIIP
  {
    path: '/process/miip/edit/:entityId/:processSeq/',
    name: 'EditProcessMiip',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessMIIP.vue'),
  },
  // プロセス詳細 FDCRTDOC
  {
    path: '/process/fdcrtdoc/:entityId/:processSeq/',
    name: 'ProcessFdcrtdoc',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCRTDOC.vue'),
  },
  // プロセス変更登録 FDCRTDOC
  {
    path: '/process/fdcrtdoc/edit/:entityId/:processSeq/',
    name: 'EditProcessFdcrtdoc',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCRTDOC.vue'),
  },
  // プロセス詳細 FDCOR
  {
    path: '/process/:_processId(fdcor[0-9]*)/:entityId/:processSeq/',
    name: 'ProcessFdcor',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCOR.vue'),
  },
  // プロセス変更登録 FDCOR
  {
    path: '/process/:_processId(fdcor[0-9]*)/edit/:entityId/:processSeq/',
    name: 'EditProcessFdcor',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCOR.vue'),
  },
  // プロセス詳細 FDCOI
  {
    path: '/process/:_processId(fdcoi[0-9]*)/:entityId/:processSeq/',
    name: 'ProcessFdcoi',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCOI.vue'),
  },
  // プロセス変更登録 FDCOI
  {
    path: '/process/:_processId(fdcoi[0-9]*)/edit/:entityId/:processSeq/',
    name: 'EditProcessFdcoi',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCOI.vue'),
  },
  // プロセス詳細 FDDOC
  {
    path: '/process/fddoc/:entityId/:processSeq/',
    name: 'ProcessFddoc',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDDOC.vue'),
  },
  // プロセス変更登録 FDDOC
  {
    path: '/process/fddoc/edit/:entityId/:processSeq/',
    name: 'EditProcessFddoc',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDDOC.vue'),
  },
  // プロセス詳細 ICREQ
  {
    path: '/process/:_processId(icreq[0-9]*)/:entityId/:processSeq/',
    name: 'ProcessIcreq',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessICREQ.vue'),
  },
  // プロセス変更登録 ICREQ
  {
    path: '/process/:_processId(icreq[0-9]*)/edit/:entityId/:processSeq/',
    name: 'EditProcessIcreq',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessICREQ.vue'),
  },
  // プロセス詳細 ICPERMIT
  {
    path: '/process/icpermit/:entityId/:processSeq/',
    name: 'ProcessIcpermit',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessICPERMIT.vue'),
  },
  // プロセス変更登録 ICPERMIT
  {
    path: '/process/icpermit/edit/:entityId/:processSeq/',
    name: 'EditProcessIcpermit',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessICPERMIT.vue'),
  },
  // アーカイブ詳細 COPO
  {
    path: '/archive/copo/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveCopo',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessCOPO.vue'),
  },
  // アーカイブ詳細 COCOS
  {
    path: '/archive/cocos/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveCocos',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessCOCOS.vue'),
  },
  // アーカイブ詳細 LCACCEPT
  {
    path: '/archive/lcaccept/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveLcaccept',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessLCACCEPT.vue'),
  },
  // アーカイブ詳細 TPARB
  {
    path: '/archive/tparb/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveTparb',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPARB.vue'),
  },
  // アーカイブ詳細 ECREQ
  {
    path: '/archive/:_processId(ecreq[0-9]*)/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveEcreq',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessECREQ.vue'),
  },
  // アーカイブ詳細 ECPERMIT
  {
    path: '/archive/ecpermit/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveEcpermit',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessECPERMIT.vue'),
  },
  // アーカイブ詳細 TPRB
  {
    path: '/archive/tprb/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveTprb',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPRB.vue'),
  },
  // アーカイブ詳細 TPRBL
  {
    path: '/archive/tprbl/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveTprbl',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPRBL.vue'),
  },
  // アーカイブ詳細 TPBL
  {
    path: '/archive/tpbl/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveTpbl',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPBL.vue'),
  },
  // アーカイブ詳細 TPBLI
  {
    path: '/archive/:_processId(tpbli[0-9]*)/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveTpbli',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessTPBLI.vue'),
  },
  // アーカイブ詳細 MIRIP
  {
    path: '/archive/:_processId(mirip[0-9]*)/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveMirip',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessMIRIP.vue'),
  },
  // アーカイブ詳細 MIIP
  {
    path: '/archive/miip/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveMiip',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessMIIP.vue'),
  },
  // アーカイブ詳細 FDCRTDOC
  {
    path: '/archive/fdcrtdoc/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveFdcrtdoc',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCRTDOC.vue'),
  },
  // アーカイブ詳細 FDCOR
  {
    path: '/archive/:_processId(fdcor[0-9]*)/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveFdcor',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCOR.vue'),
  },
  // アーカイブ詳細 FDCOI
  {
    path: '/archive/:_processId(fdcoi[0-9]*)/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveFdcoi',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDCOI.vue'),
  },
  // アーカイブ詳細 FDDOC
  {
    path: '/archive/fddoc/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveFddoc',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessFDDOC.vue'),
  },
  // アーカイブ詳細 ICREQ
  {
    path: '/archive/:_processId(icreq[0-9]*)/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveIcreq',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessICREQ.vue'),
  },
  // アーカイブ詳細 ICPERMIT
  {
    path: '/archive/icpermit/:entityId/:processSeq/:processTrx/',
    name: 'ArchiveIcpermit',
    props: true,
    component: () => import(/* webpackChunkName: "Process" */ '@/views/ProcessICPERMIT.vue'),
  },
  // 取引データマップ
  {
    path: '/datamap/',
    name: 'TradingDataMap',
    props: true,
    component: () => import(/* webpackChunkName: "TradingDataMap" */ '@/views/TradingDataMap.vue'),
  },
  // 社内向けページ
  // 請求情報
  {
    path: '/staff/application-invoices/',
    name: 'StaffApplicationInvoices',
    component: () => import(/* webpackChunkName: "StaffApplicationInvoices" */ '../views/staff/ApplicationInvoices.vue'),
  },
  // 料金管理
  {
    path: '/staff/application-contracts/',
    name: 'StaffApplicationContracts',
    component: () => import(/* webpackChunkName: "StaffApplicationContracts" */ '../views/staff/ApplicationContracts.vue'),
  },
  {
    path: '/master/',
    name: 'Setting',
    beforeEnter() {
      location.href = '/master/'
    }
  },
  {
    path: 'https://support.tradewaltz.com/access/normal',
    name: 'Help',
    beforeEnter() {
      if (location.host.includes('www.v2.tradewaltz.com') || location.host.includes('test.v2')) {
        window.open('https://tradewaltzpaiduser.zendesk.com/');
      } else {
        window.open('https://tradewaltzfreeuser.zendesk.com/');
      }
    }
  },
  {
    path: 'about:blank',
    name: 'WindowOpen',
    props: true,
    beforeEnter: (to) => {
      window.open(to.params.url);
    }
  },
  {
    path: '/404/',
    name: 'Page404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
  },
  {
    path: '*',
    redirect: '/404/'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_PATH,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      });
    })
  },
});

// LaKeel Synergy Logic の URL をセットし、SDK をインスタンス化します。
const passportClient = new FrontendPassportApiClient(process.env.VUE_APP_LAKEEL_SYNERGY_LOGIC_HOSTNAME);

router.beforeEach(async (to, from, next) => {
  // 選択をクリアします
  const selection = getSelection();
  selection.removeAllRanges() ;

  // プロセス詳細でeditモード切り替える時、ドロワー閉じる時はpassportClientを叩かない
  if (from.path.startsWith('/process/') && to.path.startsWith('/process/') && to.path.replace('edit/', '') === from.path.replace('edit/', '')) {
    next();
    return;
  }

  try {
    // 認証チェックを実施します。未認証の場合、ログイン処理が自動実行されます。
    /**
     * fetchLoginInfo(autoAuthRequest?: boolean | string, reloadOrCallback?: Function | boolean, params?: string | ObjectLiteralType, toUrl?: undefined | string): Promise<LoginInfoResponse>
     * @property {boolean | string = false} autoAuthRequest 未認証なら自動OIDC認証するかの制御。 false 以外に設定した場合、自動的に Passport のログイン画面へリダイレクトします。 文字列ならが SNS 認証連携が行われます。 そして、認証完了後、自動的にログイン情報を取得し、reload パラメータが true ならページのリロードをします。
     * @property {Function | boolean = true} reloadOrCallback ページリロードかコールバック制御。デフォルト：true(reload)。コールバックのI/F：Function(loginInfo: LoginInfoResponse): Promise
     * @property {string | ObjectLiteralType} params 文字列で指定される場合、テナントコードと見なします。ObjectLiteralTypeで指定する場合、「v1/auth/cookie-postRequest」APIのBodyと見なします。注意：SaaS対応の単一テナントを扱うサイトはテナントコードを指定しないでください。指定すると環境依存になってしまい、SaaSに利用できなくなります。
     * @property {undefined | string} toUrl ログイン完了後の遷移先を指定します。指定したページへ遷移させたい場合に利用してください。
     */
    const res = await passportClient.fetchLoginInfo(true, true, undefined, location.origin);
    const userId = (res.userId || '').toUpperCase();
    if (userId !== store.state.userId) {
      store.commit('SET_USER_ID', res.userId.toUpperCase());
    }
  } catch (e) {
    if (e instanceof PassportClientLoginException) {
      // 共通ログイン画面への遷移が発生します。画面遷移は非同期処理のため、なにもせず処理を終了することを推奨しております。
      // console.log('画面遷移中')
    }
    return;
  }
  if (!store.state.companyId) {
    try {
      await store.dispatch('GET_USER_INFORMATION');
    } catch (e) {
      try {
        await passportClient.logout(true, location.origin);
        location.reload();
      } catch (e) {
        if (e instanceof PassportClientLoginException) {
          // 共通ログイン画面への遷移。なにもせず処理を終了してください。
          return;
        } else {
          console.log('Logout failed... \n', e);
          location.reload();
        }
      }
    }
  }

  if(!store.state.userInformationV2) {
    try {
      // bff_auth_5 ロール取得（ユーザロール）の取得
      await store.dispatch('GET_USER_INFORMATION_V2')
    } catch (e) {
      try {
        await passportClient.logout(true, location.origin);
        location.reload();
      } catch (e) {
        if (e instanceof PassportClientLoginException) {
          // 共通ログイン画面への遷移。なにもせず処理を終了してください。
          return;
        } else {
          console.log('Logout failed... \n', e);
          location.reload();
        }
      }
    }
  }

  to.meta.gtmAdditionalEventData = {
    user_id: store.state.userId ? store.state.userId : '',
    user_roles: getArrayOfUserRoleLabel(store.state.userInformationV2),
    company_id: store.state.userInformationV2?.companyId ? store.state.userInformationV2.companyId : '',
    company_roles: getArrayOfCompanyRoleLabel(store.state.userInformationV2),
  }

  next();
  store.dispatch('GET_UNREAD_COUNT');
})

// NavigationDuplicatedを出さないHack
// keep original function
// const _push = router.__proto__.push;

// then override it
// router.__proto__.push = function push(...args) {
//   return _push.call(this, ...args).catch(error => {
//     // avoid NavigationDuplicated
//     if (error.name !== 'NavigationDuplicated') throw error;
//   });
// };

export default router;
