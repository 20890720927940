<template>
  <el-aside v-show="!graphicalShow" style="background-color: #5E5873" width="auto" :class="[{collapse: isCollapse}, env]">
    <div class="circle" :class="{collapse: isCollapse}" @click="toggleCollapse"></div>
    <el-menu ref="menu" :default-openeds="defaultOpends" :collapse="isCollapse" :default-active="activeIndex" :router="true">
      <li class="menu_logo">
        <router-link :to="{ name: 'Dashboard' }"><img src="@/assets/images/logo.svg" class="logo"></router-link>
        <router-link :to="{ name: 'Dashboard' }"><img src="@/assets/images/logo_collapse.svg" class="logo_collapse"></router-link>
      </li>
      <li class="ribbon">{{env}}</li>
      <el-menu-item index="Dashboard" :route="{ name: 'Dashboard' }" class="first-child">
        <img class="menu_icon" src="@/assets/images/icons/icon_dashboard.svg"><span slot="title">{{$t('SideMenu.Dashboard')}}</span>
      </el-menu-item>

      <el-submenu index="General">
        <template slot="title">
          <li class="el-menu-item"><img class="menu_icon" src="@/assets/images/icons/icon_general.svg"><span slot="title">{{$t('SideMenu.General')}}</span></li>
        </template>
        <el-menu-item v-show="canAccess('NewsList')" index="NewsList" :route="{ name: 'NewsList' }">
          <span slot="title">{{$t('SideMenu.News')}}</span>
        </el-menu-item>
        <el-menu-item v-show="canAccess('ListTradingCalendar')" index="ListTradingCalendar" :route="{ name: 'ListTradingCalendar' }">
          <span slot="title">{{$t('SideMenu.Calendar')}}</span>
        </el-menu-item>
        <el-menu-item v-show="canAccess('MailHistory')" index="MailHistory" :route="{ name: 'MailHistory' }">
          <span slot="title">{{$t('SideMenu.Mail History')}}</span>
        </el-menu-item>
      </el-submenu>

      <el-submenu index="TradingWrap">
        <template slot="title">
          <li class="el-menu-item"><img class="menu_icon" src="@/assets/images/icons/icon_trading.svg"><span slot="title">{{$t('SideMenu.Trading')}}</span></li>
        </template>
        <el-menu-item v-show="canAccess('TradingDataMap')" index="TradingDataMap" :route="{ name: 'TradingDataMap' }">
          <span slot="title">{{$t('SideMenu.Trading Data Map')}}</span>
        </el-menu-item>
        <el-menu-item v-if="canAccess('ListTrading')" index="ListTrading" :route="{ name: 'ListTrading' }">
          <span slot="title">{{$t('SideMenu.Trading List')}}</span>
        </el-menu-item>
        <!-- <el-menu-item v-if="canAccess('ListTrading')" index="ListTradingFlow" :route="{ name: 'ListTradingFlow' }">
          <span slot="title">{{$t('SideMenu.Trading Flow')}}</span>
        </el-menu-item> -->
        <el-menu-item index="ListAttachFile" :route="{ name: 'ListAttachFile' }">{{$t('SideMenu.Attach File')}}</el-menu-item>
        <el-menu-item v-if="canAccess('Approval') && (isApprover || isViewer)" index="Approval" :route="{ name: 'Approval' }">
          <span slot="title">{{$t('SideMenu.Approval Required')}}</span>
        </el-menu-item>
        <el-menu-item v-if="canAccess('Archive')" index="Archive" :route="{ name: 'Archive' }">
          <span slot="title">{{$t('SideMenu.Archive')}}</span>
        </el-menu-item>
      </el-submenu>


      <el-submenu index="Category">
        <template slot="title">
          <li class="el-menu-item"><img class="menu_icon" src="@/assets/images/icons/icon_category.svg"><span slot="title">{{$t('SideMenu.Category')}}</span></li>
        </template>
        <!-- <template slot="title"><div @click.stop>

          <li class="el-menu-item" v-else><img class="menu_icon" src="@/assets/images/icons/icon_trading.svg"><span slot="title">{{$t('SideMenu.Trading')}}</span></li>
        </div></template> -->

        <el-menu-item v-show="canAccess('ListSalesContract')" index="ListSalesContract" :route="{ name: 'ListSalesContract' }">{{$t('SideMenu.Contract')}}</el-menu-item>
        <el-menu-item v-show="canAccess('ListLC')" index="ListLC" :route="{ name: 'ListLC' }">{{$t('SideMenu.L/C')}}</el-menu-item>
        <el-menu-item v-show="canAccess('ListExCustomsClearance')" index="ListExCustomsClearance" :route="{ name: 'ListExCustomsClearance' }">{{$t('SideMenu.Export Customs Clearance')}}</el-menu-item>
        <el-menu-item v-show="canAccess('ListTransport')" index="ListTransport" :route="{ name: 'ListTransport' }">{{$t('SideMenu.Transport')}}</el-menu-item>
        <el-menu-item v-show="canAccess('ListMarineInsurance')" index="ListMarineInsurance" :route="{ name: 'ListMarineInsurance' }">{{$t('SideMenu.Marine Insurance')}}</el-menu-item>
        <el-menu-item v-show="canAccess('ListFinalDocs')" index="ListFinalDocs" :route="{ name: 'ListFinalDocs' }">{{$t('SideMenu.Final Documents')}}</el-menu-item>
        <el-menu-item v-show="canAccess('ListImCustoms')" index="ListImCustoms" :route="{ name: 'ListImCustoms' }">{{$t('SideMenu.Import Customs Clearance')}}</el-menu-item>
      </el-submenu>


      <el-submenu index="Pool" v-show="canAccess('ListExCustomsPermit') || canAccess('ListImCustomsPermit') || canAccess('ListIpDnPool') || canAccess('ListLcPool')">
        <template slot="title">
          <li class="el-menu-item"><img class="menu_icon" src="@/assets/images/icons/icon_pool.svg"><span slot="title">{{$t('SideMenu.Pool')}}</span></li>
        </template>
        <!-- 輸出許可書プール -->
        <el-menu-item v-show="canAccess('ListExCustomsPermit')" index="ListExCustomsPermit" :route="{ name: 'ListExCustomsPermit' }">{{$t('SideMenu.Export Customs Permit')}}</el-menu-item>
        <!-- IP,DNプール -->
        <el-menu-item v-show="canAccess('ListIpDnPool')" index="ListIpDnPool" :route="{ name: 'ListIpDnPool' }">{{$t('SideMenu.I/P, D/N')}}</el-menu-item>
        <!-- LCプール -->
        <el-menu-item  v-show="canAccess('ListLcPool')" index="ListLcPool" :route="{ name: 'ListLcPool' }">{{$t('SideMenu.L/C')}}</el-menu-item>
        <!-- 輸入許可書プール -->
        <el-menu-item v-show="canAccess('ListImCustomsPermit')" index="ListImCustomsPermit" :route="{ name: 'ListImCustomsPermit' }">{{$t('SideMenu.Import Customs Permit')}}</el-menu-item>
      </el-submenu>

      <el-submenu index="Staff" v-if="isTwAdmin">
        <template slot="title">
          <li class="el-menu-item"><i class="el-icon-set-up menu-icon" style="text-align: left; font-size:20px"></i>
            <span slot="title">{{$t('SideMenu.Staff')}}</span>
          </li>
        </template>
        <!-- 請求情報 -->
        <el-menu-item v-if="isTwAdmin" index="StaffApplicationInvoices" :route="{ name: 'StaffApplicationInvoices' }">{{$t('SideMenu.Staff Application Invoices')}}</el-menu-item>
        <!-- 料金管理 -->
        <el-menu-item v-if="isTwAdmin" index="StaffApplicationContracts" :route="{ name: 'StaffApplicationContracts' }">{{$t('SideMenu.Staff Application Contracts')}}</el-menu-item>
       </el-submenu>

      <li class="menu_footer">
        <ul>
          <el-menu-item index="Setting" @click="openWindow('Setting')" class="first-child">
            <img class="menu_icon" src="@/assets/images/icons/icon_settings.svg"><span slot="title">{{$t('SideMenu.Setting')}}</span>
          </el-menu-item>
          <el-menu-item v-if="isAdmin" index="Help" @click="openWindow('Help')">
            <img class="menu_icon" src="@/assets/images/icons/icon_help.svg"><span slot="title">{{$t('SideMenu.Help')}}</span>
          </el-menu-item>
        </ul>
      </li>
    </el-menu>
  </el-aside>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'TwAside',
    data() {
      return {
        isCollapse: false,
        defaultOpends: ['TradingWrap', 'Category'],
      };
    },
    computed: {
      ...mapGetters(['isApprover', 'isAdmin', 'isViewer', 'canAccess', 'isShipper', 'isFwd']),
      ...mapState(['tradingListMode', 'graphicalShow']),
      activeIndex() {
        return this.$route.name || 'Dashboard';
      },
      env() {
        if (location.host.includes('trial.v2')) {
          return 'Trial';
        } else if (location.host.includes('test.v2')) {
          return 'Training';
        }
        return undefined;
      },
      // TW管理者かどうか
      isTwAdmin() {
        return this.$store.getters.isTwAdmin;
      },
    },
    created() {
      this.setDefaultOpends();
    },
    methods: {
      toggleCollapse() {
        this.isCollapse = !this.isCollapse;
        if (this.isCollapse) {
          // TABでツールチップにフォーカスしなくする
          setTimeout(() => {
            const tooltips = this.$el.querySelectorAll('.el-tooltip');
            [...tooltips].forEach(tooltip => {
              tooltip.setAttribute('tabindex', '-1');
            });
          }, 500);
        }
        this.$store.commit('SET_ASIDE', this.isCollapse);
      },
      // 新しいタブでリンクを開きます
      openWindow(name) {
        this.$router.push({name: name});
      },
      openPage(url) {
        // @ts-ignore
        // window.open(url, '_blank');
        location.href = url;
      },
      // メニューの開閉初期値を設定します
      setDefaultOpends() {
        let opends = ['TradingWrap', 'Category'];
        if (!this.isShipper && !this.isFwd) {
          opends.push('Pool');
        }
        this.defaultOpends = opends;
      },
    }
  }
</script>

<style lang="scss">
.el-menu::-webkit-scrollbar {
  width: 0px;
}
  .el-aside {
    position: relative;
    height: 100%;
    color: $color_white;
    font-size: 14px;
    overflow: visible;
    // overflow-y: auto;
    @include drop_shadow;

    .el-menu {
      background: $color_gray_800;
      border-right: 0;
      z-index: 10;
      height: 100%;
      overflow-y: scroll!important;
      scrollbar-width: none;
    }

    .el-menu:not(.el-menu--collapse) {
      width: 220px;
    }

    .el-submenu__title, .el-menu-item-group__title, .el-menu-item, .el-submenu__title i, .el-submenu__title a {
      color: $color_white;
    }

    .el-submenu__title:focus, .el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover, .el-menu-item.is-active {
      background: $color_gray_600;
      color: $color_white!important;
    }

    .ribbon {
      display: none;
      position: absolute;
      top: 58px;
      left: 0;
      right: 0;
      height: 24px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 1;
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      white-space: nowrap;
    }

    &.Trial {
      .el-menu {
        background: $color_trial;
      }

      .el-submenu__title:focus, .el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover, .el-menu-item.is-active {
        background: $color_trial_active;
      }

      .ribbon {
        display: flex;
        color: $color_trial;
      }
    }

    &.Training {
      .el-menu {
        background: $color_training;
      }

      .el-submenu__title:focus, .el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover, .el-menu-item.is-active {
        background: $color_training_active;
      }

      .ribbon {
        display: flex;
        color: $color_training;
      }
    }

    .el-submenu__title {
      padding: 0!important;

      .el-menu-item {
        padding: 0 19px!important;
      }
    }

    .el-menu-item {
      display: flex;
      align-items: center;
    }

    .el-menu-item, .el-submenu__title {
      height: 51px;
      // line-height: 51px;
      margin-bottom: 0;

      border-top: 1px solid rgba(#fff, 0.2);
    }

    .el-submenu .el-menu .el-menu-item {
      padding-left: 19px!important;
      color: rgba(#fff, 0.7);
    }

    .el-menu-item.first-child, .el-menu--inline .el-menu-item {
      border-top: 1px solid transparent;
    }

    .el-submenu__title {
      border-top: 0;
    }

    li.menu_logo {
      height: 82px;
      padding-top: 15px;
      padding-left: 15px;
    }

    .el-menu--collapse li.menu_logo {
      height: 82px;
      padding-top: 19px;
      padding-left: 12px;
    }

    img.logo {
      flex-shrink: 0;
      display: block;
      width: 141px;
    }

    img.logo_collapse {
      flex-shrink: 0;
      display: none;
      width: 28px;
    }

    .el-menu--collapse {
      width: 52px;

      .ribbon {
        display: none;
      }

      .el-submenu__title {
        padding: 0!important;

        .el-menu-item {
          padding: 0 16px!important;
          min-width: 0;
        }
      }

      .el-menu-item, .el-submenu__title {
        border-bottom-color: transparent;
      }

      .el-tooltip {
        padding: 0 17px!important;
      }

      img.logo {
        display: none;
      }

      img.logo_collapse {
        display: block;
      }

      img.menu_icon {
        margin-right: 0;
      }
    }

    img.menu_icon {
      width: 20px;
      height: auto;
      margin-right: 8px;
    }

    li.menu_footer {
      position: sticky;
      top: calc(100vh - 104px - 26.5px);
      left: 0;
      margin-top: 52px;

      > ul {
        margin: 0;
        padding: 0;
      }

      .el-menu-item {
        font-size: 12px;
      }
    }

    .circle {
      position: absolute;
      width: 18px;
      height: 28px;
      background: $color_gray_300 url(../../assets/images/icons/chevron_left_black.svg) no-repeat right center;
      background-size: 16px auto;
      border-radius: 19px 0 0 19px;
      top: 17px;
      right: 0;
      z-index: 11;
      cursor: pointer;

      &:hover {
        opacity: .5;
      }

      &.collapse {
        position: absolute;
        background: $color_gray_300 url(../../assets/images/icons/chevron_right_black.svg) no-repeat left center;
        background-size: 16px auto;
        border-radius: 0 19px 19px 0;
        right: -18px;
      }
    }
  }
</style>
